$(document).ready(function () {
  //
  // DropDown
  //

  (function () {
    if (window.matchMedia("(min-width: 992px)").matches) {
      $(".dropdown").hover(
        function () {
          const $this = $(this);
          $this.addClass("show");
          $this.find(".dropdown-menu").first().addClass("show");
        },
        function () {
          const $this = $(this);
          $this.removeClass("show");
          $this.find(".dropdown-menu").first().removeClass("show");
        }
      );
    } else {
      $(".dropdown").off("mouseenter mouseleave");
    }

    // DropDown Submenu
    $(".dropdown-submenu a[data-toggle='dropdown']").on("click", function (e) {
      if (!$(this).next().hasClass("show")) {
        $(this)
          .parents(".dropdown-menu")
          .first()
          .find(".show")
          .removeClass("show");
      }

      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass("show");

      $(this)
        .parents("li.nav-item.dropdown.show")
        .on("hidden.bs.dropdown", function (e) {
          $(".dropdown-submenu .show").removeClass("show");
        });

      return false;
    });

    // Nav Submenu
    $('a[data-toggle="submenu"]').on("click", function (e) {
      e.preventDefault();
      $(this).next(".nav-submenu").toggleClass("show");
    });
  })();

  //
  // Scroll To Top
  //

  (function () {
    var $document = $(document);
    var $scrollTotop = $(".scroll-to-top");
    if ($scrollTotop.length > 0) {
      if ($document.scrollTop() > 300) {
        $scrollTotop.show();
      }
      $document.on("scroll", function () {
        if ($document.scrollTop() > 300) {
          $scrollTotop.show();
        } else {
          $scrollTotop.fadeOut();
        }
      });
      $scrollTotop.click(function (e) {
        e.preventDefault();
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          500
        );
        return false;
      });
    }
  })();

  //
  // Slideout
  //

  (function () {
    var panel = document.getElementById("mu-panel");
    var menu = document.getElementById("mu-sidebar");
    if (!panel || !menu) {
      return;
    }

    var slideout = new Slideout({
      panel: panel,
      menu: menu,
      duration: 300,
      easing: "ease",
      padding: 300,
      tolerance: 40,
      touch: false,
      side: "left",
    });

    var transition = {
      transition: "transform " + slideout._duration + "ms " + slideout._fx,
    };

    var startStyle = $.extend(
      { transform: "translateX(" + slideout._padding + "px)" },
      transition
    );

    var endStyle = $.extend({ transform: "translateX(0px)" }, transition);

    $(".mu-toggler").on("click", function () {
      slideout.toggle();
    });

    var slideoutClose = function (e) {
      e.preventDefault();
      slideout.close();
    };

    var header = $("#mu-header");
    var menu = $("#mu-menu");
    var sidebtn = $("#mu-sidebtn");

    slideout
      .on("beforeopen", function () {
        this.$backdrop = $('<div class="page-backdrop"></div>');
        this.$backdrop.appendTo(document.body);
        this.$backdrop[0].offsetHeight;
        this.$backdrop.css($.extend({ opacity: 0.5 }, startStyle));

        header.css(startStyle);
        menu.css(startStyle);
        sidebtn.css(startStyle);
      })
      .on("open", function () {
        this.$backdrop.bind("click", slideoutClose);
      })
      .on("beforeclose", function () {
        this.$backdrop.unbind("click", slideoutClose);
        this.$backdrop.remove();

        header.css(endStyle);
        menu.css(endStyle);
        sidebtn.css(endStyle);
      })
      .on("translate", function (translated) {
        var style = { transform: "translateX(" + translated + "px)" };
        this.$backdrop.css(style);

        header.css(style);
        menu.css(style);
        sidebtn.css(style);
      });
  })();

  //
  // 导航动效
  //

  (function () {
    var docElem = document.documentElement,
      header = $("#header"),
      didScroll = false;

    scrollPage();
    window.addEventListener(
      "scroll",
      function (event) {
        if (!didScroll) {
          didScroll = true;
          setTimeout(scrollPage, 250);
        }
      },
      false
    );

    function scrollPage() {
      var scrollY = window.pageYOffset || docElem.scrollTop;
      if (scrollY >= 150) {
        header.removeClass("expand");
      } else {
        header.addClass("expand");
      }
      didScroll = false;
    }
  })();

  //
  // 百度地图
  //

  (function () {
    var mapBox = $("#map-box");
    var mapBoxWidth = mapBox.width();
    if (mapBoxWidth > 420) {
      mapBoxWidth = 420;
    }
    mapBox.height(mapBoxWidth);

    if (mapBox.length > 0) {
      var mapInfo = $.extend(
        {
          lng: 87.586399, // 经度
          lat: 43.875055, // 纬度
          title: "新疆百疆图网络服务有限公司", // 公司名称
          message: "地址：乌市新市区长沙路158号绿苑雅筑2号楼4楼", // 地址
        },
        window.MAP_INFO
      );

      // 百度地图API功能
      var map = new BMap.Map("map-box");
      var point = new BMap.Point(mapInfo.lng, mapInfo.lat);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      map.centerAndZoom(point, 15);

      var opts = {
        width: 200, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: mapInfo.title, // 信息窗口标题
        enableMessage: true, //设置允许信息窗发送短息
        message: mapInfo.message,
      };
      var infoWindow = new BMap.InfoWindow(mapInfo.message, opts); // 创建信息窗口对象
      // map.openInfoWindow(infoWindow, point); //开启信息窗口
      marker.addEventListener("click", function () {
        map.openInfoWindow(infoWindow, point); //开启信息窗口
      });

      var top_left_navigation = new BMap.NavigationControl(); //左上角，添加默认缩放平移控件

      map.addControl(top_left_navigation);
    }
  })();

  //
  // Banner
  //

  (function () {
    var swiper = new Swiper(".mu-banner", {
      autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  })();
});
